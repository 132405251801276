<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-wan</v-icon>
        Networkings
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title>
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              :to="{ path: 'networkings/adicionar' }"
              align="center"
              color="xbColor"
              class="white--text"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="networkings"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="goToNetworking"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { all as fetchNetworkings } from "@/api/admin/usuarios.js";

export default {
  name: "Networkings",

  data() {
    return {
      loading: true,
      networkings: [],
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "celular",
          value: "celular",
        },
      ];
    },
  },

  methods: {
    goToNetworking(item) {
      this.$router.push({
        path: `/networkings/usuario/${item.id}`,
        query: { nome: item.nome },
      });
    },
    getNetworkings() {
      this.loading = true;
      fetchNetworkings()
        .then((response) => {
          this.networkings = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getNetworkings();
  },
};
</script>

<style></style>
